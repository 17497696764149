export function initScrollPosition() {

	let targets = document.querySelectorAll('[data-scroll-position]');

	if(!targets.length) return;

	targets.forEach(target => {
		target.setAttribute('style','--scroll-position: 0%; --scroll-position-decimal: 0;');
		setScrollPosition(target, true);
	});

	document.addEventListener('scroll', ()=>{
		targets.forEach(target => {
			setScrollPosition(target, true);
		});
	})

}

function setScrollPosition(target, checkActive = false) {

	var entryOnly = target.getAttribute('data-scroll-position').includes('entry-only');
	var viewportOffset = target.getBoundingClientRect();
	var percentPerPixel = 100 / (window.innerHeight - 150); // -150 off rootMargin
	var percentFromTop = (viewportOffset.top * percentPerPixel);

	if(entryOnly && percentFromTop < 50) return;

	// round the numbers between 0 and 100
	if(percentFromTop < 0) percentFromTop = 0;
	if(percentFromTop > 100) percentFromTop = 100;

	var percent = (100 - percentFromTop).toFixed();
	var decimal = (percent / 100).toFixed(2);

	target.setAttribute('style','--scroll-position: '+percent+'%; --scroll-position-decimal: '+decimal+';');
}